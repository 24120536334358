<template>
  <div style="margin-top: 50px;">
    <div class="buttonContainer">
      <v-btn class="btnStyle" to="/" style="background-color: orange">Back to main Website</v-btn>
      <v-btn class="btnStyle" to="/hiddenmenu" style="background-color: darkred">Back to hidden menu</v-btn>
      <div>
        <v-btn class="btnStyle" style="background-color:red" @click="stopGame()">STOP GAME!</v-btn>
      </div>
    </div>
    <div class="canvas" :style="{height: this.height + 'px', width: this.width+ 'px'}">
      <h1 style="text-align:center;" v-if="!gameStarted">Start by pressing an arrow key!</h1>
      <div class="snake" :style="{left: this.snakeX +'px', top: this.snakeY + 'px'}"></div>
      <div class="food" :style="{left: this.foodX +'px', top: this.foodY + 'px'}"></div>
      <div class="snake" :key="tail.positionInArray" v-for="tail in tails"
           :style="{left: tail.x +'px', top: tail.y + 'px'}"></div>
    </div>
  </div>

</template>

<script>
export default {
  name: "Snake",
  data() {
    return {
      gameSpeed: 50,
      gameStarted: false,
      cubeSize: 20,
      width: 600,
      height: 600,
      snakeX: 280,
      snakeY: 280,
      xDir: 0,
      yDir: 0,
      foodX: -1000,
      foodY: -1000,
      tailLength: 0,
      tails: []
    }
  },
  methods: {
    setDir(x, y) {
      this.xDir = x;
      this.yDir = y;
    },
    startGame() {
      this.gameStarted = true;
      this.spawnFood();
      this.gameLoop();
    },
    stopGame(){
      this.gameStarted = false;
      this.xDir =0;
      this.yDir = 0;
      this.tails = [];
      this.tailLength = 0;
      this.snakeX = 280;
      this.snakeY = 290;
    },
    eat() {
      if (this.snakeX === this.foodX && this.snakeY === this.foodY) {
        this.spawnFood();
        this.grow();
      }
    },
    grow() {
      this.tailLength += 1;
      if (this.tails[0] === undefined) {
        this.tails = [{x: this.snakeX, y: this.snakeY, positionInArray: 0}];
      } else {
        const pos = this.tails.length;
        this.tails.push({x: this.snakeX - this.xDir, y: this.snakeY - this.yDir, positionInArray: pos})
      }
    },
    updateTail() {
      if (this.tails[0] !== undefined) {
        // eslint-disable-next-line for-direction
        for (let i = this.tails.length - 1; i >= 0; i--) {
          if (this.tails[i].positionInArray === 0) {
            this.tails[i] = {
              x: this.snakeX - this.xDir,
              y: this.snakeY - this.yDir,
              positionInArray: 0
            }
          } else {
            this.tails[i] = {
              x: this.tails[i - 1].x,
              y: this.tails[i - 1].y,
              positionInArray: this.tails[i].positionInArray
            }
          }
        }
      }
    },
    spawnFood() {
      const cols = this.width / this.cubeSize;
      const rows = this.height / this.cubeSize;
      this.foodX = Math.floor(Math.random() * cols) * this.cubeSize;
      this.foodY = Math.floor(Math.random() * rows) * this.cubeSize;
    },
    didWeDie() {
      if (this.didWeEatOurTail() || this.didWeHitTheWall()) {
        this.stopGame();
      }
    },
    didWeEatOurTail() {
      for (let i = 0; i < this.tails.length; i++) {
        if (this.snakeY === this.tails[i].y && this.snakeX === this.tails[i].x) {
          return true;
        }
      }
      return false;
    },
    didWeHitTheWall() {
      const shouldSnakeLoseTailOnWallHit = false;
      const x = this.snakeX;
      const y = this.snakeY;
      if (x > this.width - this.cubeSize) {
        this.snakeX = 0;
        return shouldSnakeLoseTailOnWallHit;
      } else if (x < 0) {
        this.snakeX = this.width - this.cubeSize;
        return shouldSnakeLoseTailOnWallHit;
      } else if (y > this.height - this.cubeSize) {
        this.snakeY = 0;
        return shouldSnakeLoseTailOnWallHit;
      } else if (y < 0) {
        this.snakeY = this.height - this.cubeSize;
        return shouldSnakeLoseTailOnWallHit;
      }

    }
    ,
    gameLoop() {
      let gameloop = window.setInterval(() => {
        this.snakeX += this.xDir;
        this.snakeY += this.yDir;
        this.eat();
        this.updateTail();
        this.didWeDie();
        if (this.gameStarted === false){
          window.clearInterval(gameloop);
        }
      }, this.gameSpeed);

    }

  },
  created() {
    window.addEventListener('keydown', (e) => {
      if (e.key === 'ArrowUp') {
        if (this.yDir !== 20) {
          this.setDir(0, -20);
        }
      } else if (e.key === 'ArrowDown') {
        if (this.yDir !== -20) {
          this.setDir(0, 20);
        }
      } else if (e.key === 'ArrowLeft') {
        if (this.xDir !== 20) {
          this.setDir(-20, 0)
        }
      } else if (e.key === 'ArrowRight') {
        if (this.xDir !== -20) {
          this.setDir(20, 0)
        }
      }

      if (e.key === 'g') {
        this.grow();
      }

      if (!this.gameStarted) {
        this.startGame();
      }
    });
  }
}
</script>

<style scoped>
.canvas {
  background: darkgrey;
  margin: auto;
  border: thin solid black;
  position: relative;
}

.snake {
  background: white;
  width: 20px;
  height: 20px;
  border: thin solid black;
  position: absolute;
}

.food {
  background: red;
  width: 20px;
  height: 20px;
  border: thin solid black;
  position: absolute;
  border-radius: 50%;
}

.btnStyle{
  margin: 5px;
  border-radius: 25px;
}

.buttonContainer{
  align-content: center;
  justify-content: center;
  text-align: center;
}
</style>
