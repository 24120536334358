<template>
  <div id="Home" class="pagePositioning">
    <TopMenu :activeMenuItem="tabName"/>
    <div class="hometextContainer">
      <div class="homeText">
        <h1><span class="CT">D</span>ennis <span class="CT">v</span>an <span class="CT">B</span>eelen</h1>
        <h5>A software engineer with love for motorsport.</h5>
        <div style="margin-top: 50px">
          <p>
            <v-icon color="white">email</v-icon>
            {{ emailAdress }}
          </p>
          <p>
            <v-icon color="white">mdi-linkedin</v-icon>
            <a :href="linkedInAdress">LinkedIn</a></p>
          <p>
            <v-icon color="white">mdi-facebook</v-icon>
            <a :href="facebookAdress">Facebook</a></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopMenu from "./TopMenu";

export default {
  name: "Home",
  data() {
    return {
      tabName: "Home",
      emailAdress: "Dvbeelen@gmail.com",
      facebookAdress: "https://www.facebook.com/dennis.beelen.18",
      linkedInAdress: "https://www.linkedin.com/in/dennis-van-beelen/",
    }
  },
  methods: {},
  components: {
    TopMenu
  }

}
</script>

<style scoped>
.pagePositioning {
  margin-top: -50px;
}

.hometextContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
}

.homeText {
  font-size: 175%;
  color: white;
  font-weight: bold;
  backdrop-filter: blur(10px);
  padding: 10px;
  border-radius: 25px;
}

.CT {
  color: #FF6500;
}

</style>
