<template>
  <v-app class="mainContainer">
    <div>
      <ParticleJS v-if="doWeWantParticles" class="particlesStyling" :themeColor="themingColor"></ParticleJS>
    </div>
    <router-view/>
  </v-app>
</template>

<script>
import ParticleJS from "./assets/ParticleJS";

export default {
  components: {
    ParticleJS,
  },
  data() {
    return {
      themingColor: '#000000',
      doWeWantParticles: true,
      currentRoute: this.$router.currentRoute.path
    }
  },
  created() {
    this.getPageTheming();
  },
  methods: {
    getPageTheming() {
      switch (this.currentRoute){
        case '/raidhelper':
          this.doWeWantParticles = false;
          break;
        case '/DashboardRaidHelper':
          this.doWeWantParticles = false;
          break;
        case '/monsterKiller':
          this.doWeWantParticles = false;
          break;
        default:
          this.doWeWantParticles = true;
      }
    }
  },
  watch: {
    $route(to) {
      this.currentRoute = to.path;
      this.getPageTheming();
    }
  }
};
</script>

<style>
.mainContainer{
  background-color: #444!important;
}

::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #555;
}

::-webkit-scrollbar
{
  width: 12px;
  background-color: #555;
}

::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #333;
}
</style>
