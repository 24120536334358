<template>
  <div class="hometextContainer">
    <div class="gameBoard">
      <div>
        <v-btn class="btnStyle" to="/" style="background-color: orange">Back to main Website</v-btn>
        <v-btn class="btnStyle" to="/hiddenmenu" style="background-color: darkred">Back to hidden menu</v-btn>
      </div>
      <h1>Tic Tac Toe</h1>
      <table>
        <tr>
          <td @click="play" id="00"><h1>{{ field[0][0] }}</h1></td>
          <td @click="play" id="01" class="vert"><h1>{{ field[0][1] }}</h1></td>
          <td @click="play" id="02"><h1>{{ field[0][2] }}</h1></td>
        </tr>
        <tr>
          <td @click="play" id="10" class="hori"><h1>{{ field[1][0] }}</h1></td>
          <td @click="play" id="11" class="vert hori"><h1>{{ field[1][1] }}</h1></td>
          <td @click="play" id="12" class="hori"><h1>{{ field[1][2] }}</h1></td>
        </tr>
        <tr>
          <td @click="play" id="20"><h1>{{ field[2][0] }}</h1></td>
          <td @click="play" id="21" class="vert"><h1>{{ field[2][1] }}</h1></td>
          <td @click="play" id="22"><h1>{{ field[2][2] }}</h1></td>
        </tr>
      </table>
      <h1 v-if="!winner && !tie">Current player = {{ currentPlayer }}</h1>
      <h1 v-if="winner && !tie">Player {{ currentPlayer }} has won the GAME !!!</h1>
      <h1 v-if="tie">It is a TIE !!!!</h1>
      <h1>
        <v-btn @click="resetGame">resetGame</v-btn>
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "tictactoe",
  data() {
    return {
      playerX: 'X',
      playerO: 'O',
      currentPlayer: '',
      movecounter: 0,
      amountX: 0,
      amountO: 0,
      field: [
        ['', '', ''],
        ['', '', ''],
        ['', '', '']],
      winner: false,
      tie: false,
    }
  },
  methods: {
    play(e) {
      const x = e.currentTarget.id[0];
      const y = e.currentTarget.id[1];
      if (this.isCellEmpty(x, y)) {
        this.movecounter++;
        this.field[x][y] = this.currentPlayer;
        if (this.winner === false) this.isThereAWinner(x, y);
        if (this.winner === false) this.changePlayer();
      }
    },
    isCellEmpty(x, y) {
      if (this.field[x][y] === '') return true;
      else return false;
    },
    changePlayer() {
      if (this.currentPlayer === this.playerX) {
        this.currentPlayer = this.playerO;
      } else {
        this.currentPlayer = this.playerX;
      }
    },
    isThereAWinner(x, y) {
      this.checkXAxis(x);
      this.checkYAxis(y);
      this.checkDiagonals();
      this.checkForTie();
    },
    checkXAxis(x) {
      for (let i = 0; i < 3; i++) {
        if (this.field[x][i] === this.playerX) this.amountX++;
        if (this.field[x][i] === this.playerO) this.amountO++;
      }
      if (this.amountX === 3 || this.amountO === 3) this.winner = true;
      this.resetCounters();
    },
    checkYAxis(y) {
      for (let i = 0; i < 3; i++) {
        if (this.field[i][y] === this.playerX) this.amountX++;
        if (this.field[i][y] === this.playerO) this.amountO++;
      }
      if (this.amountX === 3 || this.amountO === 3) this.winner = true;
      this.resetCounters();
    },
    checkDiagonals() {
      if (this.field[0][0] === this.playerX && this.field[1][1] === this.playerX && this.field[2][2] === this.playerX) this.winner = true;
      if (this.field[0][2] === this.playerX && this.field[1][1] === this.playerX && this.field[2][0] === this.playerX) this.winner = true;
      if (this.field[0][0] === this.playerO && this.field[1][1] === this.playerO && this.field[2][2] === this.playerO) this.winner = true;
      if (this.field[0][2] === this.playerO && this.field[1][1] === this.playerO && this.field[2][0] === this.playerO) this.winner = true;
    },
    checkForTie() {
      if (this.movecounter >= 9) {
        this.tie = true;
      }
    },
    resetGame() {
      this.field = [
        ['', '', ''],
        ['', '', ''],
        ['', '', '']];
      this.winner = false;
      this.tie = false;
      this.currentPlayer = this.playerX;
      this.movecounter = 0;
    },
    resetCounters() {
      this.amountX = 0;
      this.amountO = 0;
    }
  },
  created() {
    this.resetGame();
  }
}
</script>

<style scoped>
h1 {
  text-align: center;
}

td {
  width: 100px;
  height: 100px;
}

table {
  margin: 5px auto;
}

.gameBoard {
  background-color: white;
  border-radius: 25px;
}

.hometextContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
}

.vert {
  border-left: 2px solid black;
  border-right: 2px solid black;
}

.hori {
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

.btnStyle{
  margin: 5px;
  border-radius: 25px;
}
</style>