<template>
  <div class="raidhelperhomecontainer">
    <v-btn color="green" style="width: 100%; margin-bottom: 35px; margin-top: 10px" @click="createNewRaidJson()">Create
      a new raid json!
    </v-btn>


    <v-card color="#555" style="padding-top: 25px">
      <v-select label="Raid" :items="raidData" item-text="name" v-model="chosenRaid" return-object
                @input="raidClicked"
                class="raidSelector"></v-select>
      <div v-if="chosenRaid !== null">
        <h1 style="margin-left: 25px" :style="{'color': themeColor,}">{{ chosenRaid.name }}</h1>
        <v-btn color="green" class="importExport" @click="exportRaidData()">export to: {{ chosenRaid.name }}.json
        </v-btn>
        <v-btn color="#ff0000" style="" @click="deleteCurrentSelectedRaid()">Delete
          current
          selected raid.
        </v-btn>
      </div>
    </v-card>

    <v-card color="#555" style="margin-top: 25px">
      <p style="color: indianred">!!! Watch out, if the name in the json has the same name as a existing one it
        overrides that raid. The primary key is the raid name!!!</p>
      <p style="color: lightgrey">To add a new raid get a new raid Json from the top button, change the name, theme
        and boss info. Then import that json.</p>
      <v-row class="importExport">
        <v-file-input
            show-size
            label="File input"
            accept=".json"
            v-model="importedFile"
        ></v-file-input>
        <v-btn color="red" @click="importRaidData()">import new raid data with .json</v-btn>
      </v-row>
    </v-card>

    <v-snackbar v-model="snackbar">
      {{ snackbarText }}
      <v-btn text color="red" @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>

import firebaseCRUD from "@/global/firebaseCRUD";

export default {
  name: "DashboardRaidHelper",
  components: {},
  data() {
    return {
      currentActiveRaid: 'Castle_Nathria',
      raidData: [],
      chosenRaid: null,
      themeColor: '#000',
      importedFile: null,
      importedData: null,
      snackbar: false,
      snackbarText: "Saved succesfully!",
      newCleanRaidData: {
        name: "<<NAME HERE>>",
        themeColor: "#ffffff",
        bosses: [
          {
            superShortVersion: "- <<SuperShortBulletPoint>> \n",
            name: "<<BOSSNAME>>",
            genericInfo: "<<BOSS INFO>>",
            infoboxes: [
              {
                roles: {
                  tank: true,
                  healer: true,
                  dps: true
                },
                abilityImage: null,
                title: "<<ABILITYNAME>>",
                extraDangerous: false,
                info: "<<ABILITYDESCRIPTION>>"
              },
            ],
            positioningPhoto: "<<POSITIONINGPHOTOLINK>>",
            videoLink: "<<LINKTOYOUTUBEVIDEO>>"
          },
        ]
      }
    }
  },
  created() {
    this.getDataFromDB();
  },
  methods: {
    getDataFromDB() {
      const data = firebaseCRUD.getWholeCollection('RaidHelper');
      data.then((dataSet) => {
        if (dataSet !== undefined) {
          for (let i = 0; i < dataSet.length; i++) {
            this.raidData.push(dataSet[i].dataSet);
          }
          this.chosenRaid = this.raidData[this.getRaidIndexByName()];
          this.raidClicked();
        }
      });
    },
    getRaidIndexByName() {
      for (let i = 0; i < this.raidData.length; i++) {
        if (this.raidData[i].name === this.currentActiveRaid) {
          return i;
        }
      }
      return 0;
    },
    raidClicked() {
      this.themeColor = this.chosenRaid.themeColor;
    },
    importRaidData() {
      if (this.importedFile === null) {
        this.showSnackbar('No Json file selected!');
      } else {
        let reader = new FileReader();
        reader.readAsText(this.importedFile);
        reader.onload = () => {
          this.importedData = reader.result;
          this.saveToDB(JSON.parse(this.importedData));
        }
      }
    },
    saveToDB(importedData) {
      let saved = firebaseCRUD.update('RaidHelper', importedData.name, importedData);
      if (saved) {
        this.showSnackbar("New raid imported and saved as: " + importedData.name);
        this.importedFile = null;
        this.importedData = null;
        this.refreshData();
      }
    },
    refreshData(){
      this.resetData();
      this.getDataFromDB();
    },
    resetData(){
      this.raidData = [];
    },
    exportRaidData() {
      this.generateJson(this.chosenRaid, this.chosenRaid.name);
    },
    createNewRaidJson() {
      this.generateJson(this.newCleanRaidData, 'NEW_RAID');
    },
    generateJson(raidData, raidName) {
      let jsonString = JSON.stringify(raidData, null, 2);
      let dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(jsonString);

      let exportFileDefaultName = raidName + '.json';

      let linkElement = document.createElement('a');
      linkElement.setAttribute('href', dataUri);
      linkElement.setAttribute('download', exportFileDefaultName);
      linkElement.click();
    },
    deleteCurrentSelectedRaid() {
      let deleted = firebaseCRUD.deleteDocument('RaidHelper', this.chosenRaid.name);
      if (deleted){
        this.showSnackbar(this.chosenRaid.name + " is successfully deleted!");
        this.refreshData();
      }
    },
    showSnackbar(text) {
      this.snackbarText = text;
      this.snackbar = true;
    }
  }
}
</script>

<style scoped>
.raidhelperhomecontainer {
  height: 100vh;
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  background-color: #444;
  color: #141414;
  align-content: center;
}

.raidSelector {
  margin: 0 25px 0 25px;
}

.importExport {
  margin: 10px 15px;
}
</style>