<template>
  <div class="pagePositioning aboveParticlesContainer">
    <TopMenu :activeMenuItem="tabName"/>
    <div class="contentContainer">
      <v-list class="mainList" v-if="itemArray[0] !== undefined">
        <v-list-item
            v-for="item in itemArray"
            :key="item.id + 'item'">
          <v-card class="cards">
            <v-img
                :src="item.img"
                :alt="item.img"
                class="topPicture"></v-img>
            <v-card-title class="cardTitle">
              <span class="CT">{{ item.title[0] }}</span>
              {{ item.title.substring(1) }}
            </v-card-title>
            <v-card-subtitle class="cardTitle" style="font-size: 130%">{{ item.subtitle }}</v-card-subtitle>
            <v-card-text class="cardText" style="white-space: pre-line;">{{ item.text }}</v-card-text>
            <div v-if="item.usedSkills[0] !== ''">
              <v-card-title class="cardTitle chipTitle">
                <span class="CT">U</span>sed techniques
              </v-card-title>
              <v-chip
                  :ripple="false"
                  v-for="skills in item.usedSkills"
                  :key="item.id + skills"
                  class="skillChip"
              >{{ skills }}
              </v-chip>
            </div>

          </v-card>
        </v-list-item>
      </v-list>
      <v-progress-circular
          class="spinner"
          v-else
          indeterminate
          color="primary"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import TopMenu from "./TopMenu";
import firebaseCRUD from "../../global/firebaseCRUD";

export default {
  name: "Experiences",
  data() {
    return {
      tabName: "Experiences",
      itemArray: [],
    }
  },
  components: {TopMenu},
  methods: {
    getDataFromDB() {
      const data = firebaseCRUD.get('resumeWebsite', this.tabName);
      data.then((dataSet) => {
        if (dataSet !== undefined) {
          this.itemArray = dataSet;
          this.splitUsedSkills();
        }
      });
    },
    splitUsedSkills() {
      for (let i = 0; i < this.itemArray.length; i++) {
        this.itemArray[i].usedSkills = this.itemArray[i].usedSkills.split(" ");
      }
    }
  },
  created() {
    this.getDataFromDB();
  }
}
</script>

<style scoped>
.pagePositioning {
  margin-top: 0;
}

.aboveParticlesContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: transparent;
  margin: 0;
  padding: 0;
}

.contentContainer {
  position: absolute;
  top: 70px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  max-width: 1000px;
}

.mainList {
  background-color: transparent;
  margin: 0;
  padding: 0;
  width: 100%;
}

.cards {
  border-radius: 25px !important;
  background-color: black;
  border: 2px solid #FF6500;
  margin: 15px 5%;
  width: 90%;
}

.cardTitle {
  color: white !important;
  font-size: 200%;
}

.cardText {
  color: white !important;

}

.spinner {
  margin: 0 5px 7px 5px;
}

.chipTitle {
  padding-bottom: 6px;
}

.skillChip {
  margin: 0 0 15px 15px;
  font-weight: bold;
}

.CT {
  color: #FF6500;
}

.topPicture{
  height: 200px;
}

@media (max-width: 650px) {
  .contentContainer {
    width: 100%;
  }

  .cards {
    width: 100%;
    margin: 15px 0;
  }

  .cardTitle {
    font-size: 175%
  }

  .topPicture{
    height: 150px;
  }
}
</style>
