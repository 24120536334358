<template>
  <div id="Login" class="loginContainer">
    <TopMenu :activeMenuItem="tabName"/>
    <div class="body">
      <v-card class="loginCard">
        <!--                logged out stuff-->
        <v-card-title class="loginCardTitle" v-if="!loggedIn">Admin Login</v-card-title>
        <v-card-title class="loginCardTitle" v-if="loggedIn">Admin Menu</v-card-title>
        <v-card-actions class="vcardaction topAndBottomMarginPaddingZero" v-if="!loggedIn">
          <v-text-field label="Email" v-model="email" v-on:keyup.enter="login"></v-text-field>
        </v-card-actions>
        <v-card-actions class="vcardaction topAndBottomMarginPaddingZero" v-if="!loggedIn">
          <v-text-field label="Password"
                        :type="passVisibleCheckBox ? 'text' : 'password'"
                        v-model="pass"
                        v-on:keyup.enter="login"></v-text-field>
        </v-card-actions>
        <v-checkbox class="topAndBottomMarginPaddingZero" style="padding-left: 16px" v-if="!loggedIn"
                    v-model="passVisibleCheckBox" :label="'Show Password'"></v-checkbox>
        <v-card-actions class="vcardaction" v-if="!loggedIn">
          <v-btn class="buttonFullWidth" color="primary" @click="login">Login</v-btn>
        </v-card-actions>
        <v-card-actions v-if="!loggedIn">
          <v-btn text small class="buttonFullWidth" @click="signUp">Sign up</v-btn>
        </v-card-actions>
        <!--                logged in stuff -->
        <v-card-actions v-if="loggedIn">
          <v-btn to="Dashboard" class="buttonFullWidth">dashboard: resume website</v-btn>
        </v-card-actions>
        <v-card-actions v-if="loggedIn">
          <v-btn to="DashboardRaidHelper" class="buttonFullWidth">dashboard: RaidHelper</v-btn>
        </v-card-actions>
        <v-card-actions v-if="loggedIn">
          <v-btn color="red" class="buttonFullWidth" @click="logout">Logout</v-btn>
        </v-card-actions>
      </v-card>
      <v-snackbar v-model="snackbar">
        {{ snackbarText }}
        <v-btn text color="red" @click="snackbar = false">Close</v-btn>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import TopMenu from "../resume/TopMenu";

const firebase = require("../../global/firebaseConfig");
export default {
  name: "Login",
  data() {
    return {
      tabName: "Login",
      email: "",
      pass: "",
      loggedIn: false,
      passVisibleCheckBox: false,
      allowNewSignUps: false,
      snackbar: false,
      snackbarText: "Successful login."
    }
  },
  components: {TopMenu},
  methods: {

    //TODO: check for valid pass en email.
    login() {
      const authPromis = firebase.auth.signInWithEmailAndPassword(this.email, this.pass);
      authPromis.catch(e => this.snackbarText = e.message.toString(), this.snackbar = true)
    },
    signUp() {
      if (this.allowNewSignUps) {
        const authPromis = firebase.auth.createUserWithEmailAndPassword(this.email, this.pass);
        authPromis.catch(e => console.log(e.message))
      } else {
        this.snackbarText = "Sorry we currently don't accept new sign ups.";
        this.snackbar = true;
      }

    },
    logout() {
      firebase.auth.signOut();
    }
  },
  created() {
    firebase.auth.onAuthStateChanged(user => {
      if (user) {
        this.loggedIn = true;
      } else {
        this.loggedIn = false;
      }
    });
  }
}
</script>

<style scoped>

.loginContainer {

}

.loginCard {
  margin: 40px auto;
  max-width: 500px;
}

.buttonFullWidth {
  width: 100%;
  padding: 16px;
}

.vcardaction {
  padding: 16px;
}

.topAndBottomMarginPaddingZero {
  margin: 0 auto;
  padding-top: 0;
  padding-bottom: 0;
}
</style>
