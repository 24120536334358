<template>
  <div id="TopMenu" style="margin-bottom: 50px">
    <!--        menu bar-->
    <v-app-bar color="primary" class="header headerBar style_bottom">
      <!--            main menu buttons -->
      <div class="headerLeft logoStyle style_full">
        <h3><span class="CT">D</span>ennis <span class="CT">v</span>an <span class="CT">B</span>eelen</h3>
      </div>
      <div class="spacer"></div>
      <div class="headerCenter smRemoval">
        <v-btn v-for="(item,i) in menuItems" :key="i" @click="changePage" :to="item.url"
               style="margin: 3px"
               :style="item.active ? 'background: rgba(0,0,0,0.05)' : 'background: rgba(0,0,0,0)'"
               :class="{'style_left' : i === 0, 'style_right' : i === menuItems.length -1}">
          <div :style="item.active ? 'color: white; font-weight: bold;' : 'color:black'"><b>{{ item.title }}</b>
          </div>
        </v-btn>
      </div>
      <div class="spacer"></div>
      <v-btn text class="smRemoval style_full adminButton" @click="changePage" :to="'Login'"
             :style="loginActive ? 'color: white; font-weight: bold;' : 'color:black'">
        <v-icon>settings</v-icon>
      </v-btn>

      <!--            hamburger menu for mobile users-->
      <div class="headerRight hamburgerMenu">
        <v-menu>
          <template v-slot:activator="{on}">
            <v-btn text v-on="on" class="style_full">
              <v-icon>menu</v-icon>
            </v-btn>
          </template>
          <v-list style="padding: 0" class="style_full">
            <v-list-item v-for="(item, index) in menuItems" :key="index" @click="changePage"
                         :to="item.url"
                         :class="{'style_top' : index === 0, 'style_bottom' : index === menuItems.length -1}">
              <v-list-item-title
                  :style="item.active ? 'color: #ff9800; font-weight: bold;' : 'color:black'">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="changePage" :to="'Login'" style="horiz-align: center"
                         :style="loginActive ? 'color: #ff9800; font-weight: bold;' : 'color:black'">
              <v-icon style="padding-left: 30px">settings</v-icon>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "TopMenu",
  props: ['activeMenuItem'],
  data: () => ({
    menuItems: [
      {title: 'Home', url: 'Home', active: false},
      {title: 'About me', url: 'AboutMe', active: false},
      {title: 'Projects', url: 'Projects', active: false},
      {title: 'Experiences', url: 'Experiences', active: false},
      {title: 'Skills', url: 'Skills', active: false},
    ],
    loginActive: false,
  }),
  methods: {
    checkActiveMenuItem() {

      if (this.activeMenuItem === 'Login') {
        this.loginActive = true;
      } else {
        this.loginActive = false;
        for (let i = 0; i < this.menuItems.length; i++) {
          if (this.menuItems[i].title === this.activeMenuItem) {
            this.menuItems[i].active = true;
          } else {
            this.menuItems[i].active = false;
          }
        }
      }

    },
    changePage() {
      this.$router.go(0);
    }
  },
  created() {
    this.checkActiveMenuItem();
  }
}
</script>

<style scoped>

.headerBar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  margin-bottom: 50px;
}

.headerLeft {
  float: left;
  padding-left: 10px;
}

.headerRight {
  float: right;
  padding-right: 10px;
}

.hamburgerMenu {
  display: none;
}

.CT {
  color: #FF6500;
}

.logoStyle {
  padding: 5px 15px;
  background-color: black;
  color: white;
  position: absolute;
  left: 10px;
}

.adminButton{
  position: absolute;
  right: 10px;
}

@media (max-width: 945px) {
  .smRemoval {
    display: none;
  }

  .hamburgerMenu {
    display: block;
  }
}
</style>
