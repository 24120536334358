<template>
  <div id="Dashboard">
    <TopMenu :activeMenuItem="tabName"/>
      <v-card class="dashboard">
        <v-card-title>Resume settings</v-card-title>
        <v-tabs centered>
        <v-tab v-for="dash in dashboards" class="dashboardSelector" :key="dash.name" @click="currentComponent = dash.component">{{dash.name}}</v-tab>
      </v-tabs>
        <div class="cardDiv">
          <component :is="currentComponent"></component>
        </div>
      </v-card>
  </div>
</template>

<script>
import TopMenu from "../resume/TopMenu";
import DashExperiences from "./DashboardBoards/DashExperiences";
import DashProjects from "./DashboardBoards/DashProjects";
import DashSkills from "./DashboardBoards/DashSkills";
import DashAboutMe from "@/components/admin/DashboardBoards/DashAboutMe";

export default {
  name: "Dashboard",
  data() {
    return {
      tabName: "Dashboard",
      dashboards: [
        {name: 'About me', component: 'DashAboutMe'},
        {name: 'Projects', component: 'DashProjects'},
        {name: 'Experiences', component: 'DashExperiences'},
        {name: 'Skills', component: 'DashSkills'}
        ],
      currentComponent: '',
    }


  }, created() {
    this.currentComponent = this.dashboards[0].component;
  },
  components: {DashSkills, DashProjects, DashExperiences, TopMenu, DashAboutMe}
}
</script>

<style scoped>

.leftPane {
  margin: 30px 5px auto 40px;
  position: fixed;
}

.rightPane {
  margin: 30px 40px 5px auto;
}

.menuButtons {
  margin: 5px;
  width: 125px;
}

.cardDiv {
  margin: 10px 16px 0 16px;
}

.dashboard{
  width: 80%;
  margin: 5% 10%;
}

@media (max-width: 960px) {
  .leftPane {
    margin: 30px 5px auto auto;
    position: relative;
  }

  .rightPane {
    margin: 30px auto 5px auto;
  }
}
</style>
