<template>
  <div class="pagePositioning aboveParticlesContainer">
    <TopMenu :activeMenuItem="tabName"/>
    <div class="contentContainer">
      <div class="mainList" v-if="dataLoaded">
        <v-row>
          <div v-for="(item,index) in itemArray"
               :key="item + index"
               class="skillCards">
            <v-card class="cards">
              <h3 class="skillTitle" v-if="index === 0">Programming skills</h3>
              <h3 class="skillTitle" v-else>Other skills</h3>
              <ul class="skillList">
                <li v-for="(i, index) in item"
                    :key="i + index"
                    class="cardText">
                  <v-chip
                      color="orange"
                      style="margin:5px 0; width: 98%;">
                    <img :src="i.img" class="skillLogo" alt="logo">
                    <h3 style="align-self: center; margin-left: 5px">{{ i.title }}</h3>
                  </v-chip>
                </li>
              </ul>
            </v-card>
          </div>
        </v-row>
      </div>
      <v-progress-circular
          class="spinner"
          v-else
          indeterminate
          color="primary"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import TopMenu from "./TopMenu";
import firebaseCRUD from "../../global/firebaseCRUD";

export default {
  name: "Skills",
  data() {
    return {
      tabName: "Skills",
      itemArray: [[], []],
      dataLoaded: false,
    }
  },
  components: {TopMenu},
  methods: {
    getDataFromDB() {
      const leftData = firebaseCRUD.get('resumeWebsite', "leftSkills");
      leftData.then((dataSet) => {
        if (dataSet !== undefined) {
          this.itemArray[0] = dataSet;
        }
      });
      const rightData = firebaseCRUD.get('resumeWebsite', "rightSkills");
      rightData.then((dataSet) => {
        if (dataSet !== undefined) {
          this.itemArray[1] = dataSet;
          this.dataLoaded = true;
        }
      });
    }
  },
  created() {
    this.getDataFromDB();
  }
}
</script>

<style scoped>
.pagePositioning {
  margin-top: 0;
}

.aboveParticlesContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: transparent;
  margin: 0;
  padding: 0;
}

.contentContainer {
  position: absolute;
  top: 70px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  max-width: 1000px;
}

.mainList {
  background-color: transparent;
  margin: 0;
  padding: 0;
  width: 100%;
}

.cards {
  border-radius: 25px !important;
  background-color: black;
  border: 2px solid #FF6500;
  margin: 15px auto;
}


.cardText {
  color: white !important;
}

.spinner {
  margin: 0 5px 7px 5px;
}

.skillList {
  list-style-type: none;
  padding: 0 0 0 5px;
}

.skillCards {
  margin-left: auto;
  margin-right: auto;
  width: 300px;
}

.skillLogo {
  width: 40px;
  height: 40px;
  padding: 2px;
}

.skillTitle{
  color: white;
  text-align: center;
}

</style>
