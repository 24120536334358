<template>
  <div class="alignCenter">
    <v-btn color="green" class="editCard" @click="saveToDB">Save</v-btn>
    <v-textarea rows="12" v-model="mainInfo.txt" label="Text"
                class="editCardTextField" counter></v-textarea>
    <v-text-field v-model="mainInfo.img" label="ImageUrl" class="editCardTextField"></v-text-field>
    <v-list>
      <v-list-item v-for="item in itemArray" :key="item.id" class="basicMargin">
        <v-card class="editCard">
          <v-row class="editCardTextField">
            <v-text-field v-model="item.title" label="Title"></v-text-field>
            <v-btn color="grey" style="margin: 10px 0 0 2px" @click="moveItemUp(item.id)">
              <v-icon>mdi-arrow-up</v-icon>
            </v-btn>
            <v-btn color="grey" style="margin: 10px 0 0 2px" @click="moveItemDown(item.id)">
              <v-icon>mdi-arrow-down</v-icon>
            </v-btn>
            <v-btn color="red" style="margin: 10px 0 0 2px" @click="removeItemFromArray(item.id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-row>
          <v-text-field v-model="item.subtitle" label="Subtitle" class="editCardTextField"></v-text-field>
          <v-text-field v-model="item.img" label="ImageUrl" class="editCardTextField"></v-text-field>
          <v-container fluid>
            <v-row>
              <v-textarea rows="12" v-model="item.text" label="Text"
                          class="editCardTextField" counter></v-textarea>
              <v-img class="imagePreview"
                     :src="item.img"
              ></v-img>
            </v-row>
          </v-container>
        </v-card>
      </v-list-item>
    </v-list>
    <v-btn fab dark class="basicMargin" @click="addItemToArray">
      <v-icon>add</v-icon>
    </v-btn>
    <v-btn color="green" class="editCard" @click="saveToDB">Save</v-btn>
    <v-snackbar v-model="snackbar">
      {{ snackbarText }}
      <v-btn text color="red" @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import firebaseCRUD from "../../../global/firebaseCRUD";
import Vue from 'vue';

export default {
  name: "DashAboutMe",
  data() {
    return {
      arrayName: 'AboutMe',
      itemArray: [
        {
          id: 1,
          title: 'temp',
          subtitle: 'temporarily',
          img: "placeholder",
          text: 'placeholder',
        }
      ],
      mainInfo: {txt: '', img: ''},
      snackbar: false,
      snackbarText: "Saved succesfully!"

    }
  },
  methods: {
    addItemToArray() {
      if (this.itemArray.length <= 0) {
        this.itemArray.push({
          id: 0,
          title: '',
          subtitle: '',
          img: '',
          text: '',
        });
      } else {
        this.itemArray.push({
          id: this.generateId(this.itemArray),
          title: '',
          subtitle: '',
          img: '',
          text: '',
        });
      }

      this.logArray();
    },
    generateId(itemArray) {
      let currentHighestId = 0;
      for (let i = 0; i < itemArray.length; i++) {
        if (itemArray[i].id > currentHighestId) {
          currentHighestId = itemArray[i].id;
        }
      }
      return currentHighestId + 1;
    },
    saveToDB() {
      const bool = firebaseCRUD.update('resumeWebsite', this.arrayName, this.itemArray)
          && firebaseCRUD.update('resumeWebsite', 'ABoutMeMainText', this.mainInfo);
      if (bool) {
        this.showSnackbar("Saved succesfully!");
      }
    },
    logArray() {
      console.log(this.itemArray)
    },
    getDataFromDB() {
      const listData = firebaseCRUD.get('resumeWebsite',this.arrayName);
      listData.then((dataSet) => {
        if (dataSet === undefined) {
          this.itemArray.push({id: 1, title: '', subtitle: '', img: '', text: ''});
        } else {
          this.itemArray = dataSet;
        }
      });
      const mainData = firebaseCRUD.get('resumeWebsite', 'ABoutMeMainText');
      mainData.then((dataSet) => {
        this.mainInfo = dataSet;
      });
    },
    removeItemFromArray(Id) {
      this.itemArray.splice(this.findItemInArray(Id), 1);
      this.showSnackbar("Item removed. Save to make it permanent!");
    },
    moveItemUp(Id) {
      let itemLocation = this.findItemInArray(Id);
      let tempItem = this.itemArray[itemLocation];
      if (itemLocation - 1 < 0) {
        this.showSnackbar('Is already at the top cant move further up.');
      } else {
        Vue.set(this.itemArray, itemLocation, this.itemArray[itemLocation - 1]);
        Vue.set(this.itemArray, itemLocation - 1, tempItem);
      }
    },
    moveItemDown(Id) {
      let itemLocation = this.findItemInArray(Id);
      let tempItem = this.itemArray[itemLocation];
      if (itemLocation + 2 > this.itemArray.length) {
        this.showSnackbar('Is already at the bottom cant move further down.');
      } else {
        Vue.set(this.itemArray, itemLocation, this.itemArray[itemLocation + 1]);
        Vue.set(this.itemArray, itemLocation + 1, tempItem);
      }
    },
    findItemInArray(Id) {
      for (let i = 0; i < this.itemArray.length; i++) {
        if (this.itemArray[i].id === Id) {
          return i;
        }
      }
    },
    showSnackbar(text) {
      this.snackbarText = text;
      this.snackbar = true;
    }
  },
  created() {
    this.getDataFromDB();
  }
}
</script>

<style scoped>
.basicMargin {
  margin: 20px;
}

.editCard {
  width: 100%;
}

.editCardTextField {
  margin: 10px 20px;
}

.alignCenter {
  text-align: center;
}

.imagePreview {
  max-width: 350px;
  max-height: 250px;
  margin: 5px 25px 5px 5px;
}
</style>
