<template>
  <div id="Home" class="pagePositioning">
    <TopMenu :activeMenuItem="tabName"/>
    <div class="hometextContainer">
      <div class="homeText">
        <h1>Sorry this page is only available for admins!</h1>
        <h2>Please return to the publicly available pages!</h2>
        <img src="../../assets/images/NoAccess.png" alt="No Access">
      </div>
    </div>
  </div>
</template>

<script>
import TopMenu from "./TopMenu";

export default {
  name: "Unauthorized",
  data() {
    return {
      tabName:'Unauthorized'
    }
  },
  methods: {},
  components: {
    TopMenu
  }

}
</script>

<style scoped>
.pagePositioning {
  margin-top: -50px;
}

.hometextContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
}

.homeText {
  font-size: 175%;
  color: white;
  font-weight: bold;
  backdrop-filter: blur(10px);
  padding: 10px;
  border-radius: 25px;
}

.CT {
  color: #FF6500;
}

</style>
