<template>
  <div v-if="boss !== null" class="MainContainer">
    <h1 class="BossName">{{ boss.name }}</h1>
    <p class="BossGuideLink">Must have seen the video before pull: <a class="actualLink" :href="boss.videoLink"
                                                                      target="_blank"> {{ boss.videoLink }} </a></p>
    <div class="row">
      <v-card class="col basics">
        <h3>Basics:</h3>
        <p class="BossGenericInfo">{{ boss.genericInfo }}</p>
        <p class="BossGenericInfo"> {{ boss.superShortVersion }}</p>
      </v-card>
      <v-card class="col positioning">
        <h3>Positioning</h3>
        <img alt="Positioning Photo" class="positioningPhoto" :src="boss.positioningPhoto">
      </v-card>
    </div>
    <div class="row">
      <div v-for="infoBox in boss.infoboxes" :key="infoBox.title" class="col-lg-6 infoBoxContainer">
        <v-card class="infoBox">
          <div class="row" style="width: 100%">
            <a class="spellTitle" :href="infoBox.spelId">{{ infoBox.title }}</a>
            <img v-if="infoBox.roles.tank" alt="RoleIcon" class="roleIcon"
                 src="../../../assets/images/raidHelper/TankIcon.png">
            <img v-if="infoBox.roles.healer" alt="RoleIcon" class="roleIcon"
                 src="../../../assets/images/raidHelper/HealerIcon.png">
            <img v-if="infoBox.roles.dps" alt="RoleIcon" class="roleIcon"
                 src="../../../assets/images/raidHelper/DpsIcon.png">
            <img src="../../../assets/images/raidHelper/DANGER.png" class="dangerIcon" alt="DANGER"
                 v-if="infoBox.extraDangerous">
          </div>
          <div class="row" style="width: 100%; margin-top: 5px">
            <p>{{ infoBox.info }}</p>
            <img alt="abilityPhoto" class="abilityPhoto" v-if="infoBox.abilityImage != null"
                 :src="infoBox.abilityImage">
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['boss', 'theme'],
  name: "RaidBossInformation",
  mounted() {
    let whTooltipScript = document.createElement('script');
    whTooltipScript.setAttribute('src', 'https://wow.zamimg.com/widgets/power.js');
    document.head.appendChild(whTooltipScript);
  }
}
</script>

<style scoped>
.MainContainer {
  margin: 40px;
}

.BossName {

}

.BossGuideLink {
  font-weight: bold;
}

.actualLink {
  color: dodgerblue;
}

.BossGenericInfo {

}

.row {
  margin: 0;
}

.basics {
  min-width: 220px;
  background-color: #555;
  margin: 5px 5px 5px 0;
  white-space: pre-line;
}

.positioning {
  background-color: #555;
  margin: 5px 0 5px 5px;
}

.positioningPhoto {
  width: 554px;
  height: 308px;
  /*max-width: 100%;*/
  transition: transform .2s;
  position: relative;
  z-index: 99;
  border-radius: 5px;
}

.abilityPhoto {
  /*width: 400px;*/
  max-height: 150px;
  max-width: 100%;
  transition: transform .2s;
  position: relative;
  margin-left: auto;
  right: 0;
  z-index: 98;
  border-radius: 5px;
}

.positioningPhoto:hover {
  transform: scale(1.6);
}

.infoBox {
  margin: 5px 0 5px 0;
  padding: 5px 10px 7px 10px;
  background-color: #555;
  position: relative;
  z-index: 1;
}

.infoBoxContainer {
  margin: 0;
  padding: 0;
  float: none;
  display: inline-block;
}

.infoBoxContainer:nth-child(odd) {
  padding-right: 5px;
}

.infoBoxContainer:nth-child(even) {
  padding-left: 5px;
}


.roleIcon {
  width: 35px;
  height: 35px;
}

.dangerIcon {
  width: 45px;
  height: 45px;
  position: absolute;
  right: 10px;
}

.spellTitle{
  margin-right: 20px;
  color: #141414;
  font-weight: bold;
  font-size: 20px;
}

@media (max-width: 1360px) {
  .basics {
    margin: 5px 0 5px 0;
  }

  .positioning {
    margin: 5px 0 5px 0;
  }

  .positioningPhoto {
    width: 454px;
    height: 250px;
  }
}

@media (max-width: 1206px) {

  .infoBoxContainer {
    padding: 0 !important;
  }
}

@media (max-width: 900px) {

  .positioningPhoto {
    width: 354px;
    height: 200px;
  }
}
</style>

