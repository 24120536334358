<template>
  <div id="app" class="monsterContainer">
    <section class="row">
      <div class="col-sm-6">
        <h1 class="text-center">YOU</h1>
        <div class="healthbar">
          <div class="healthbar text-center"
               style="background-color: green; margin: 0; color: white;"
               :style="{width: playerHealth+'%'}">
            {{ playerHealth }}
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <h1 class="text-center">MONSTER</h1>
        <div class="healthbar">
          <div class="healthbar text-center"
               style="background-color: green; margin: 0; color: white;"
               :style="{width: (monsterHealth/2)+'%'}">
            {{ monsterHealth }}
          </div>
        </div>
      </div>
    </section>
    <section class="row controls" v-if="!gameIsRunning">
      <div class="small-12 columns">
        <button id="start-game" @click="switchMode">START NEW GAME</button>
      </div>
    </section>
    <section class="row controls" v-if="gameIsRunning">
      <div class="small-12 columns">
        <button id="attack" @click="attack">ATTACK</button>
        <button id="special-attack" @click="specialAttack">SPECIAL ATTACK</button>
        <button id="heal" @click="heal">HEAL</button>
        <button id="give-up" @click="switchMode">GIVE UP</button>
      </div>
    </section>
    <section class="row log">
      <div class="small-12 columns">
        <ul>
          <div v-for="log in combatLog" :key="log">
            <li style="background-color: lightgreen" v-if="log.isPlayer">
              {{ log.text }}
            </li>
            <li style="background-color: lightcoral" v-else>
              {{ log.text }}
            </li>
          </div>
        </ul>
      </div>
    </section>
  </div>
</template>


<script>
export default {
  data() {
    return {
      playerHealth: 100,
      monsterHealth: 200,
      gameIsRunning: false,
      combatLog: []
    }
  },
  methods: {
    attack() { //medium dmg range, but monster has same range.
      let damage = this.calculateAttack(1, 8);
      this.monsterHealth -= damage;
      this.combatLog.unshift({isPlayer: true, text: 'player hit monster for: ' + damage});

      damage = this.calculateAttack(3, 15);
      this.playerHealth -= damage;
      this.combatLog.unshift({isPlayer: false, text: 'Monster hit player for:  ' + damage});
      this.checkForWinner();
    },
    specialAttack() { //has huge dmg range, but monster deals always +5 dmg.
      let damage = this.calculateAttack(1, 20);
      this.monsterHealth -= damage;
      this.combatLog.unshift({isPlayer: true, text: 'player special-hit monster for: ' + damage});

      damage = this.calculateAttack(8, 15);
      this.playerHealth -= damage;
      this.combatLog.unshift({isPlayer: false, text: 'Monster hit player for:  ' + damage});
      this.checkForWinner();
    },
    heal() {
      let heal = this.playerHeal(5, 25);
      this.playerHealth += heal;
      this.combatLog.unshift({isPlayer: true, text: 'HEALING yourself for : ' + heal});

      let damage = this.calculateAttack(3, 15);
      this.playerHealth -= damage;
      this.combatLog.unshift({isPlayer: false, text: 'Monster hit player for:  ' + damage});
      this.checkForWinner();
    },
    calculateAttack(min, max) {
      return Math.floor(Math.random() * ((max + 1) - min)) + min;
    },
    playerHeal(min, max) {
      return Math.floor(Math.random() * ((max + 1) - min)) + min;
    },
    checkForWinner() {
      if (this.playerHealth <= 0) {
        window.alert('YOU HAVE BEEN KILLED!');
        this.playerHealth = 0;
        this.switchMode();
      } else if (this.monsterHealth <= 0) {
        window.alert('PLAYER HAS DEFEATED THE MONSTER!');
        this.monsterHealth = 0;
        this.switchMode();
      }
    },
    switchMode() {
      if (!this.gameIsRunning) {
        this.resetData();
      }
      this.gameIsRunning = !this.gameIsRunning;
    },
    resetData() {
      this.playerHealth = 100;
      this.monsterHealth = 200;
      this.combatLog = [];
    },

  },
}
</script>


<style scoped>
.text-center {
  text-align: center;
}

.monsterContainer{
  width: 400px;
  margin: 50px auto 0 auto;
}

.healthbar {
  width: 80%;
  height: 40px;
  background-color: #eee;
  margin: auto;
  transition: width 500ms;
}

.controls, .log {
  margin-top: 30px;
  text-align: center;
  padding: 10px;
  border: 1px solid #ccc;
  box-shadow: 0px 3px 6px #ccc;
}

.turn {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 22px;
}

.log ul {
  list-style: none;
  font-weight: bold;
  text-transform: uppercase;
}

.log ul li {
  margin: 5px;
}

.log ul .player-turn {
  color: blue;
  background-color: #e4e8ff;
}

.log ul .monster-turn {
  color: red;
  background-color: #ffc0c1;
}

button {
  font-size: 20px;
  background-color: #eee;
  padding: 12px;
  box-shadow: 0 1px 1px black;
  margin: 10px;
}

#start-game {
  background-color: #aaffb0;
}

#start-game:hover {
  background-color: #76ff7e;
}

#attack {
  background-color: #ff7367;
}

#attack:hover {
  background-color: #ff3f43;
}

#special-attack {
  background-color: #ffaf4f;
}

#special-attack:hover {
  background-color: #ff9a2b;
}

#heal {
  background-color: #aaffb0;
}

#heal:hover {
  background-color: #76ff7e;
}

#give-up {
  background-color: #ffffff;
}

#give-up:hover {
  background-color: #c7c7c7;
}
</style>
