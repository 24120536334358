import Home from "./components/resume/Home";
import Experiences from "./components/resume/Experiences";
import Login from "./components/admin/Login";
import Dashboard from "./components/admin/Dashboard";
import Unauthorized from "./components/resume/Unauthorized";
import HiddenMenu from "./components/sideProjects/HiddenMenu";
import Snake from "./components/sideProjects/games/Snake";
import ParticleJS from "./assets/ParticleJS";
import Skills from "./components/resume/Skills";
import AboutMe from "./components/resume/AboutMe";
import Projects from "@/components/resume/Projects";
import PageNotFound from "@/components/resume/PageNotFound";
import tictactoe from "@/components/sideProjects/games/tictactoe";
import RaidHelperHome from "@/components/sideProjects/RaidHelper/RaidHelperHome";
import DashboardRaidHelper from "@/components/sideProjects/RaidHelper/DashboardRaidHelper";
import monsterKiller from "@/components/sideProjects/games/monsterKiller";

const routes = [
    {path: '/', component: Home},
    {path: '/Home', component: Home},
    {path: '/AboutMe', component: AboutMe},
    {path: '/Experiences', component: Experiences},
    {path: '/Login', component: Login},
    {path: '/Skills', component: Skills},
    {path: '/Projects', component: Projects},
    {path: '/Unauthorized', component: Unauthorized},
    // {path: '/Dashboard', component: Dashboard, meta: {requiresAuth: false}},  //only uncomment this offline when needed it removed the auth needed for the dashboard.
    {path: '/Dashboard', component: Dashboard, meta: {requiresAuth: true}}, // only comment this if you need the above one uncommented.
    {path: '/particle', component: ParticleJS},


    // sideProjects:
    {path: '/RaidHelper', component: RaidHelperHome},
    {path: '/DashboardRaidHelper', component: DashboardRaidHelper, meta: {requiresAuth: true}}, // only comment this if you need the above one uncommented.
    {path: '/hiddenMenu', component: HiddenMenu},
    {path: '/snake', component: Snake},
    {path: '/tictactoe', component: tictactoe},
    {path: '/monsterKiller', component: monsterKiller},
    // {path:'/iracingstats', component: IracingStats},

    //notfound
    {path: "*", component: PageNotFound}
];

export default routes;
