<template>
  <div class="alignCenter">
    <v-btn color="green" class="editCard" @click="saveToDB">Save</v-btn>
    <v-row justify="center">
      <v-list>
        <p>Programming skills</p>
        <v-list-item v-for="item in leftSkillArray" :key="item.id + item.title" class="">
          <v-card class="editCard">
            <v-text-field v-model="item.title" label="Title" class="editCardTextField titleStyle"></v-text-field>
            <v-row class="imgStyle">
              <v-text-field v-model="item.img" label="ImageUrl/logoUrl" class="editCardTextField"></v-text-field>
              <v-img class="imagePreview"
                     :src="item.img"
              ></v-img>
            </v-row>
            <v-btn color="grey" style="margin: 0 0 10px 2px" @click="moveItemUp(item.id, 'left')">
              <v-icon>mdi-arrow-up</v-icon>
            </v-btn>
            <v-btn color="grey" style="margin: 0 0 10px 2px" @click="moveItemDown(item.id, 'left')">
              <v-icon>mdi-arrow-down</v-icon>
            </v-btn>
            <v-btn color="red" style="margin: 0 0 10px 2px" @click="removeItemFromArray(item.id, 'left')">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-card>
        </v-list-item>
      </v-list>
      <v-list>
        <p>Other skills</p>
        <v-list-item v-for="item in rightSkillArray" :key="item.id + item.img" class="">
          <v-card class="editCard">
            <v-text-field v-model="item.title" label="Title" class="editCardTextField titleStyle"></v-text-field>
            <v-row class="imgStyle">
              <v-text-field v-model="item.img" label="ImageUrl/logoUrl" class="editCardTextField"></v-text-field>
              <v-img class="imagePreview"
                     :src="item.img"
              ></v-img>
            </v-row>
            <v-btn color="grey" style="margin: 0 0 10px 2px" @click="moveItemUp(item.id, 'right')">
              <v-icon>mdi-arrow-up</v-icon>
            </v-btn>
            <v-btn color="grey" style="margin: 0 0 10px 2px" @click="moveItemDown(item.id, 'right')">
              <v-icon>mdi-arrow-down</v-icon>
            </v-btn>
            <v-btn color="red" style="margin: 0 0 10px 2px" @click="removeItemFromArray(item.id, 'right')">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-card>
        </v-list-item>
      </v-list>
    </v-row>

    <v-row justify="center">
      <v-btn fab dark class="basicMargin" style="margin-right: 150px" @click="addItemToArray('left')">
        <v-icon>add</v-icon>
      </v-btn>
      <v-btn fab dark class="basicMargin" style="margin-left: 150px" @click="addItemToArray('right')">
        <v-icon>add</v-icon>
      </v-btn>
    </v-row>

    <v-btn color="green" class="editCard" @click="saveToDB">Save</v-btn>
    <v-snackbar v-model="snackbar">
      {{ snackbarText }}
      <v-btn text color="red" @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import firebaseCRUD from "../../../global/firebaseCRUD";
import Vue from 'vue';

export default {
  name: "DashSkills",
  data() {
    return {
      arrayName: 'Skills',
      snackbar: false,
      snackbarText: "Saved succesfully!",
      leftSkillArray: [],
      rightSkillArray: []
    }
  },
  methods: {
    addItemToArray(side) {
      if (side === 'left') {
        if (this.leftSkillArray.length <= 0) {
          this.leftSkillArray.push({
            id: 1000,
            title: '',
            img: ''
          });
        } else {
          this.leftSkillArray.push({
            id: this.generateId(this.leftSkillArray),
            title: '',
            img: ''
          });
        }
      } else {
        if (this.rightSkillArray.length <= 0) {
          this.rightSkillArray.push({
            id: 2000,
            title: '',
            img: ''
          });
        } else {
          this.rightSkillArray.push({
            id: this.generateId(this.rightSkillArray),
            title: '',
            img: ''
          });
        }
      }
    },
    generateId(itemArray) {
      let currentHighestId = 0;
      for (let i = 0; i < itemArray.length; i++) {
        if (itemArray[i].id > currentHighestId) {
          currentHighestId = itemArray[i].id;
        }
      }
      return currentHighestId + 1;
    },
    saveToDB() {
      const bool = firebaseCRUD.update('resumeWebsite', "leftSkills", this.leftSkillArray)
          && firebaseCRUD.update('resumeWebsite', "rightSkills", this.rightSkillArray);
      if (bool) {
        this.showSnackbar("Saved succesfully!");
      }
    },
    getDataFromDB() {
      const leftData = firebaseCRUD.get('resumeWebsite', "leftSkills");
      leftData.then((dataSet) => {
        if (dataSet === undefined) {
          this.leftSkillArray.push([{id: 1, title: '', img: ''}]);
        } else {
          this.leftSkillArray = dataSet;
        }
      });
      const rightData = firebaseCRUD.get('resumeWebsite', 'rightSkills');
      rightData.then((dataSet) => {
        if (dataSet === undefined) {
          this.rightSkillArray.push([{id: 1, title: '', img: ''}]);
        } else {
          this.rightSkillArray = dataSet;
        }
      });
    },
    removeItemFromArray(Id, side) {
      if (side === 'left') {
        this.leftSkillArray.splice(this.findItemInArray(Id, side), 1);
      } else if (side === 'right') {
        this.rightSkillArray.splice(this.findItemInArray(Id, side), 1);
      }
      this.showSnackbar("Item removed. Save to make it permanent!");
    },
    moveItemUp(Id, side) {
      let itemLocation = this.findItemInArray(Id, side);
      if (side === 'left') {
        let tempItem = this.leftSkillArray[itemLocation];
        if (itemLocation - 1 < 0) {
          this.showSnackbar('Is already at the top cant move further up.');
        } else {
          Vue.set(this.leftSkillArray, itemLocation, this.leftSkillArray[itemLocation - 1]);
          Vue.set(this.leftSkillArray, itemLocation - 1, tempItem);
        }
      } else if (side === 'right') {
        let tempItem = this.rightSkillArray[itemLocation];
        if (itemLocation - 1 < 0) {
          this.showSnackbar('Is already at the top cant move further up.');
        } else {
          Vue.set(this.rightSkillArray, itemLocation, this.rightSkillArray[itemLocation - 1]);
          Vue.set(this.rightSkillArray, itemLocation - 1, tempItem);
        }
      }
    },
    moveItemDown(Id, side) {
      let itemLocation = this.findItemInArray(Id, side);
      if (side === 'left') {
        let tempItem = this.leftSkillArray[itemLocation];
        if (itemLocation + 2 > this.leftSkillArray.length) {
          this.showSnackbar('Is already at the bottom cant move further down.');
        } else {
          Vue.set(this.leftSkillArray, itemLocation, this.leftSkillArray[itemLocation + 1]);
          Vue.set(this.leftSkillArray, itemLocation + 1, tempItem);
        }
      } else if (side === 'right') {
        let tempItem = this.rightSkillArray[itemLocation];
        if (itemLocation + 2 > this.rightSkillArray.length) {
          this.showSnackbar('Is already at the bottom cant move further down.');
        } else {
          Vue.set(this.rightSkillArray, itemLocation, this.rightSkillArray[itemLocation + 1]);
          Vue.set(this.rightSkillArray, itemLocation + 1, tempItem);
        }
      }
    },
    findItemInArray(Id, side) {
      if (side === 'left') {
        for (let i = 0; i < this.leftSkillArray.length; i++) {
          if (this.leftSkillArray[i].id === Id) {
            return i;
          }
        }
      } else if (side === 'right') {
        for (let i = 0; i < this.rightSkillArray.length; i++) {
          if (this.rightSkillArray[i].id === Id) {
            return i;
          }
        }
      }
    },
    showSnackbar(text) {
      this.snackbarText = text;
      this.snackbar = true;
    }
  },
  created() {
    this.getDataFromDB();
  }
}
</script>

<style scoped>
.basicMargin {
  margin: 20px;
}

.editCard {
  width: 100%;
  margin-top: 5px;
}

.editCardTextField {
  margin: 10px 20px;
}

.alignCenter {
  text-align: center;
}

.imagePreview {
  max-width: 40px;
  height: 40px;
  margin: 5px 50px 0 20px;
}

.imgStyle {
  margin: 0;
}
</style>
