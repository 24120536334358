<template>
  <div class="alignCenter">
    <v-btn color="green" class="editCard" @click="saveToDB">Save</v-btn>
    <v-list>
      <v-list-item v-for="item in itemArray" :key="item + item.id" class="basicMargin">
        <v-card class="editCard">
          <v-row class="editCardTextField">
            <v-text-field v-model="item.title" label="Title"></v-text-field>
            <v-btn color="grey" style="margin: 10px 0 0 2px" @click="moveItemUp(item.id)">
              <v-icon>mdi-arrow-up</v-icon>
            </v-btn>
            <v-btn color="grey" style="margin: 10px 0 0 2px" @click="moveItemDown(item.id)">
              <v-icon>mdi-arrow-down</v-icon>
            </v-btn>
            <v-btn color="red" style="margin: 10px 0 0 2px" @click="removeItemFromArray(item.id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-row>
          <v-text-field v-model="item.subtitle" label="Subtitle" class="editCardTextField"></v-text-field>
          <v-text-field v-model="item.img" label="ImageUrl" class="editCardTextField"></v-text-field>
          <v-img class="imagePreview"
                :src="item.img"
          ></v-img>
          <v-textarea rows="12" v-model="item.text" label="Text"
                      class="editCardTextField" counter></v-textarea>
          <v-row>
            <div style="margin: 5px 5px 5px 30px;width: 384px">
              <v-text-field v-model="item.imgLeft" label="imgLeft"></v-text-field>
              <v-img class="imagePreviewSmall" :src="item.imgLeft"></v-img>
            </div>
            <div style="margin: 5px;width: 384px">
              <v-text-field v-model="item.imgRight" label="imgRight"></v-text-field>
              <v-img class="imagePreviewSmall" :src="item.imgRight"></v-img>
            </div>
          </v-row>
          <v-text-field v-model="item.usedSkills" label="UsedSkills" class="editCardTextField"></v-text-field>
          <P>seperate skills with a space</P>
        </v-card>
      </v-list-item>
    </v-list>
    <v-btn fab dark class="basicMargin" @click="addItemToArray">
      <v-icon>add</v-icon>
    </v-btn>
    <v-btn color="green" class="editCard" @click="saveToDB">Save</v-btn>
    <v-snackbar v-model="snackbar">
      {{ snackbarText }}
      <v-btn text color="red" @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import firebaseCRUD from "../../../global/firebaseCRUD";
import Vue from 'vue';

export default {
  name: "DashProjects",
  data() {
    return {
      arrayName: 'Projects',
      itemArray: [
        {
          id: 1,
          title: 'temp',
          subtitle: 'temporarily',
          img: 'placeholder',
          text: 'placeholder',
          imgLeft: 'placeholder',
          imgRight: 'placeholder',
          usedSkills: ''
        }
      ],
      snackbar: false,
      snackbarText: "Saved succesfully!"
    }
  },
  methods: {
    addItemToArray() {
      if (this.itemArray.length <= 0) {
        this.itemArray.push({
          id: 0,
          title: '',
          subtitle: '',
          img: '',
          text: '',
          imgLeft: '',
          imgRight: '',
          usedSkills: ''
        });
      } else {
        this.itemArray.push({
          id: this.generateId(this.itemArray),
          title: '',
          subtitle: '',
          img: '',
          text: '',
          imgLeft: '',
          imgRight: '',
          usedSkills: ''
        });
      }

      this.logArray();
    },
    generateId(itemArray) {
      let currentHighestId = 0;
      for (let i = 0; i < itemArray.length; i++) {
        if (itemArray[i].id > currentHighestId) {
          currentHighestId = itemArray[i].id;
        }
      }
      return currentHighestId + 1;
    },
    saveToDB() {
      const bool = firebaseCRUD.update('resumeWebsite', this.arrayName, this.itemArray);
      if (bool) {
        this.showSnackbar("Saved succesfully!");
      }
    },
    logArray() {
      console.log(this.itemArray)
    },
    getDataFromDB() {
      const data = firebaseCRUD.get('resumeWebsite', this.arrayName);
      data.then((dataSet) => {
        if (dataSet === undefined) {
          this.itemArray.push({
            id: 0,
            title: '',
            subtitle: '',
            img: '',
            text: '',
            imgLeft: '',
            imgRight: '',
            usedSkills: ''
          });
        } else {
          this.itemArray = dataSet;
        }
      });
    },
    removeItemFromArray(Id) {
      this.itemArray.splice(this.findItemInArray(Id), 1);
      this.showSnackbar("Item removed. Save to make it permanent!");
    },
    moveItemUp(Id) {
      let itemLocation = this.findItemInArray(Id);
      let tempItem = this.itemArray[itemLocation];
      if (itemLocation - 1 < 0) {
        this.showSnackbar('Is already at the top cant move further up.');
      } else {
        Vue.set(this.itemArray, itemLocation, this.itemArray[itemLocation - 1]);
        Vue.set(this.itemArray, itemLocation - 1, tempItem);
      }
    },
    moveItemDown(Id) {
      let itemLocation = this.findItemInArray(Id);
      let tempItem = this.itemArray[itemLocation];
      if (itemLocation + 2 > this.itemArray.length) {
        this.showSnackbar('Is already at the bottom cant move further down.');
      } else {
        Vue.set(this.itemArray, itemLocation, this.itemArray[itemLocation + 1]);
        Vue.set(this.itemArray, itemLocation + 1, tempItem);
      }
    },
    findItemInArray(Id) {
      for (let i = 0; i < this.itemArray.length; i++) {
        if (this.itemArray[i].id === Id) {
          return i;
        }
      }
    },
    showSnackbar(text) {
      this.snackbarText = text;
      this.snackbar = true;
    }
  },
  created() {
    this.getDataFromDB();
  }
}
</script>

<style scoped>
.basicMargin {
  margin: 20px;
}

.editCard {
  width: 100%;
}

.editCardTextField {
  margin: 10px 20px;
}

.alignCenter {
  text-align: center;
}

.imagePreview {
  height: 240px;
  width: 867px;
  margin: 5px 25px 5px 5px;
}

.imagePreviewSmall{
  max-height: 216px;
  max-width: 384px;
}
</style>
