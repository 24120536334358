import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth"

export const firebaseConfig = {
    apiKey: "AIzaSyC9-eKwncMlzDbXmW1IGG-lKGcHsbOQp8E",
    authDomain: "resume-website-5daae.firebaseapp.com",
    databaseURL: "https://resume-website-5daae.firebaseio.com",
    projectId: "resume-website-5daae",
    storageBucket: "resume-website-5daae.appspot.com",
    messagingSenderId: "640421834266",
    appId: "1:640421834266:web:dd977a5d2b0e1b24afaba3",
    measurementId: "G-VY5M8BQB25"
};
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();

export {
    db,
    auth
};

// export default {
// components: {},
// methods: {
//     addToDB() {
//         db.collection("test").add({
//             first:"Dennis",
//             last:"van Beelen",
//             message: "Werk gewoon pls"
//         }).then(function (docRef) {
//             // eslint-disable-next-line no-console
//             console.log("Document written with ID: ", docRef.id);
//         }).catch(function (error) {
//             console.error("Error adding document: ", error)
//         })
//     },
//     getFromDB(){
//         db.collection("test").get().then((dataSet) => {
//             dataSet.forEach((data) => {
//                 console.log(data.data());
//             });
//         });
//     }
// }
// };