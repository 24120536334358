<template>
  <div class="raidhelperhomecontainer">
    <div class="row">
      <div class="raidhelperSelectionModule col-3" :style="{'color': themeColor,}">
        <v-select label="Raid" :items="raidData" item-text="name" v-model="chosenRaid" return-object
                  @input="raidClicked"
                  :item-color="themeColor"
                  :color="themeColor"
                  class="raidSelector"></v-select>

        <v-btn class="importExport" @click="exportRaidData()" :color="themeColor">export to json</v-btn>

        <div class="bossList" v-if="raidSelected">
          <h1 style="color: black">{{ chosenRaid.name }}</h1>
          <v-btn v-for="boss in chosenRaid.bosses" class="bossButtons" :key="boss.name" :color="themeColor"
                 @click="selectedBoss = boss">{{ boss.name }}
          </v-btn>
        </div>
      </div>
      <div class="raidhelperInformationModule col-9">
        <RaidBossInformation :boss="selectedBoss" :theme="themeColor"></RaidBossInformation>
      </div>
    </div>

  </div>
</template>

<script>
import RaidBossInformation from "@/components/sideProjects/RaidHelper/RaidBossInformation";
import firebaseCRUD from "@/global/firebaseCRUD";

export default {
  name: "RaidHelperHome",
  components: {RaidBossInformation},
  data() {
    return {
      currentActiveRaid: 'Castle_Nathria',
      themeColor: '#000',
      chosenRaid: null,
      raidSelected: false,
      selectedBoss: null,
      raidData: [],
    }
  },
  methods: {
    getDataFromDB() {
      const data = firebaseCRUD.getWholeCollection('RaidHelper');
      data.then((dataSet) => {
        if (dataSet !== undefined) {
          for (let i = 0; i < dataSet.length; i++){
            this.raidData.push(dataSet[i].dataSet);
          }
          this.chosenRaid = this.raidData[this.getRaidIndexByName()];
          this.raidClicked();
        }
      });
    },
    raidClicked() {
      this.raidSelected = true;
      this.themeColor = this.chosenRaid.themeColor;
    },
    getRaidIndexByName(){
      for (let i = 0; i < this.raidData.length; i++){
        if (this.raidData[i].name === this.currentActiveRaid){
          return i;
        }
      }
      return 0;
    },
    exportRaidData() {
      let jsonString = JSON.stringify(this.chosenRaid, null, 2);
      let dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(jsonString);

      let exportFileDefaultName = this.chosenRaid.name + '.json';

      let linkElement = document.createElement('a');
      linkElement.setAttribute('href', dataUri);
      linkElement.setAttribute('download', exportFileDefaultName);
      linkElement.click();
    },
    test(){
      console.log(this.chosenRaid)
    }

  },
  created() {
    this.getDataFromDB();
  }
}
</script>

<style scoped>
.raidhelperhomecontainer {
  height: 100vh;
  width: 100%;
  background-color: #444;
  color: #141414;
}

.row {
  width: 90%;
  height: 100vh;
  margin: 0 5%;
}

.raidhelperSelectionModule {
  border-right: 5px solid;
  overflow: auto;
  height: 100vh;
}

.raidhelperInformationModule {
  overflow: auto;
  height: 100vh;
}

.bossList {

}

.raidSelector {
  max-width: 300px;
}

.bossButtons {
  width: 100%;
  max-width: 300px;
  height: 50px !important;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: bold;
}

.importExport{
  width: 100%;
  max-width: 300px;
  height: 30px !important;
  margin-bottom: 15px;
}

@media (max-width: 1450px) {
  .bossButtons {
    font-size: 17px;
  }
}

@media (max-width: 1200px) {
  .bossButtons {
    font-size: 14px;
  }
}


</style>