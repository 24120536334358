const firebase = require("./firebaseConfig");

export default {
    async get(collectionName,documentName) {
        let data;
        await firebase.db.collection(collectionName).doc(documentName).get().then((dataSet) => {
            data = dataSet.data().dataSet;
        });
        return data;
    },

    async getWholeCollection(collectionName) {
        const snapshot = await firebase.db.collection(collectionName).get()
        return snapshot.docs.map(doc => doc.data());
    },

    // add(collectionName, dataSet) {
    //
    // },
    async update(collectionName, documentName, dataSet) {
        await firebase.db.collection(collectionName).doc(documentName).set({dataSet}).then(function () {
            console.log("Document written to FireBase!");
            return true;
        }).catch(function (error) {
            console.error("Error adding document: ", error);
            return false;
        })
    },
    async deleteDocument(collectionName, documentName) {
        await firebase.db.collection(collectionName).doc(documentName).delete().then(()=>{
            console.log('Document is Deleted from Firebase!!');
            return true;
        }).catch((error)=>{
            console.error("Error with removal of document: " + error);
            return false;
        });
    }
}