import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router';
import routes from "./routes";
import movable from "v-movable";


Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(movable);

require('./main.css');

const router = new VueRouter({routes: routes});

// checks if the route requires authentication, if so it checks if you are logged in.
const firebase = require("./global/firebaseConfig");
router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const isAuthenticated = firebase.auth.currentUser;
    if(isAuthenticated && requiresAuth) {
        next()
    }
    if (requiresAuth && !isAuthenticated) {
        next('/Unauthorized')
    } else {
        next()
    }
});


new Vue({
    vuetify,
    router,
    render: h => h(App)
}).$mount('#app');


