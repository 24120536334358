<template>
  <div class="container" style="text-align: center;">
    <div class="btnGroup">
      <h2>Hidden Menu</h2>
      <h4>Stuff i made on the side for fun.</h4>
      <v-btn class="btnStyle"  to="/" style="background-color: orange">Back to main Website</v-btn>
      <v-btn class="btnStyle" v-for="page in hiddenPages" :key="page.name" :to="page.name">{{ page.name }}</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "HiddenMenu",
  data() {
    return {
      tabName: 'HiddenMenu',
      hiddenPages: [
        {name: 'raidhelper'},
        {name: 'Snake'},
        {name: 'tictactoe'},
        {name: 'monsterKiller'}
      ]
    }
  }
}
</script>

<style scoped>
.btnGroup {
  text-align: center;
  width: 350px;
  margin: auto;
  padding: 10px;
  background: darkgrey;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.btnStyle {
  width: 80%;
  margin: 5px 20px;
}
</style>
