import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light:{
                primary: '#ff9800',
                secondary: '#ffc947',
                accent: '#c66900'
            }
        }
    },
    icons: {
        iconfont: 'md',
    }
});
