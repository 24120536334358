<template>
  <div class="pagePositioning aboveParticlesContainer">
    <TopMenu activeMenuItem="About me"/>
    <div class="contentContainer">
      <v-list class="mainList" v-if="itemArray[0] !== undefined">
        <div class="homeText">
          <h1><span class="CT">D</span>ennis <span class="CT">v</span>an <span class="CT">B</span>eelen</h1>
          <v-row class="personal-info-row">
            <div class="personal_text">
              <p style="margin: 0; padding:  0;"><span class="CT">Age:</span> {{ age }}</p>
              <p style="margin: 0; padding:  0;"><span class="CT">Location:</span> {{ location }}</p>
            </div>
            <v-img class="personal_picture" :src="mainInfo.img" alt="personal picture"></v-img>
          </v-row>
          <p style="white-space: pre-line;">{{ mainInfo.txt }}</p>
          <h1 style="margin-top: 30px"><span class="CT">H</span>obbies / <span class="CT">I</span>nterests</h1>
        </div>
        <v-list-item
            v-for="item in itemArray"
            :key="item.id + 'item'">
          <v-card class="cards">
            <v-img
                :src="item.img"
                :alt="item.img"
                class="topPicture"></v-img>
            <v-card-title class="cardTitle">
              <span class="CT">{{ item.title[0] }}</span>
              {{ item.title.substring(1) }}
            </v-card-title>
            <v-card-subtitle class="cardTitle" style="font-size: 130%">{{ item.subtitle }}</v-card-subtitle>
            <v-card-text class="cardText" style="white-space: pre-line;">{{ item.text }}</v-card-text>
          </v-card>
        </v-list-item>
      </v-list>
      <v-progress-circular
          class="spinner"
          v-else
          indeterminate
          color="primary"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import TopMenu from "./TopMenu";
import firebaseCRUD from "../../global/firebaseCRUD";

export default {
  name: "aboutMe",
  data() {
    return {
      tabName: "AboutMe",
      itemArray: [],
      birthDay: '01-08-1996',
      age: 0,
      location: 'Voorhout, The Netherlands, Earth',
      mainInfo: {}
    }
  },
  components: {TopMenu},
  methods: {
    getDataFromDB() {
      const listData = firebaseCRUD.get('resumeWebsite', this.tabName);
      listData.then((dataSet) => {
        if (dataSet !== undefined) {
          this.itemArray = dataSet;
        }
      });
      const mainData = firebaseCRUD.get('resumeWebsite', 'ABoutMeMainText');
      mainData.then((dataSet) => {
        if (dataSet !== undefined) {
          this.mainInfo = dataSet;
        }
      });
    },
    getAge() {
      let birthday = new Date(this.birthDay);
      this.age = (~~((Date.now() - birthday) / (31557600000))-1);
    }
  },
  created() {
    this.getDataFromDB();
    this.getAge();
  }
}
</script>

<style scoped>
.pagePositioning {
  margin-top: 0;
}

.aboveParticlesContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: transparent;
  margin: 0;
  padding: 0;
}

.contentContainer {
  position: absolute;
  top: 150px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  max-width: 1000px;
}

.mainList {
  background-color: transparent;
  margin: 0;
  padding: 0;
  width: 100%;
}

.cards {
  border-radius: 25px !important;
  background-color: black;
  border: 2px solid #FF6500;
  margin: 15px 5%;
  width: 90%;
}

.cardTitle {
  color: white !important;
  font-size: 200%;
}

.cardText {
  color: white !important;

}

.spinner {
  margin: 0 5px 7px 5px;
}

.homeText {
  color: white;
  font-weight: bold;
  backdrop-filter: blur(10px);
  padding: 10px;
  border-radius: 25px;
  text-align: center;
}

.CT {
  color: #FF6500;
}

.topPicture {
  height: 200px;
}

.personal_text{
  margin: 70px 0 0 0;
  text-align: left;
  width: 400px;
}

.personal_picture {
  border-radius: 25px;
  max-width: 200px;
  max-height: 200px;
  margin: 0;
}

.personal-info-row{
  margin: 5px 5px 20px 5px;
  align-content: center;
  justify-content: center;
}

@media (max-width: 650px) {
  .contentContainer {
    width: 100%;
  }

  .cards {
    width: 100%;
    margin: 15px 0;
  }

  .cardTitle {
    font-size: 175%;
  }

  .topPicture {
    height: 150px;
  }
}
</style>
